$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.navigationHeader:global(.ant-page-header) {
  background-color: var(--theme-stage-weak);
  border-bottom: solid var(--theme-stroke-width) var(--theme-stage-border);
  display: flex;
  height: var(--nav-bottom-bar-height);
  padding-bottom: 0;
  padding-top: 0;

  :global(.ant-page-header-heading) {
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;

    :global(.ant-page-header-heading-title) {
      display: flex;
      gap: 5px;
    }
    :global(.ant-page-header-heading-extra) {
      align-self: center;
    }
  }
}
@media only screen and (max-width: $breakpoint-mobile) {
  .preview {
    display: none;
  }
}
