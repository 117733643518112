$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.base {
  cursor: pointer;

  &:focus {
    box-shadow: var(--theme-ix-active);
  }
}
.inherit {
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
.disabled {
  color: var(--theme-ix-inactive);
  cursor: not-allowed;
}
.tiny {
  font-size: 11px;
}
.small {
  font-size: 12px;
}
.medium {
  font-size: 14px;
}
.large {
  font-size: 16px;
}
