$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.sectionHeader {
  display: flex;
  justify-content: space-between;
}
.pools {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .card {
    border-radius: 3px;

    :global(.ant-form-item) {
      margin-bottom: 8px;
    }
  }
}
.formItemWithLink {
  label {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
