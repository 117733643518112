$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
