$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.base {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 40px;

  img {
    height: 100px;
    width: 100px;
  }
  .title {
    color: var(--theme-status-inactive-weak);
    font-size: 14px;
  }
  span {
    color: var(--theme-status-inactive-weak);
    font-size: 12px;
    margin-top: 4px;
  }
}
