$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.list {
  font-size: 11px !important;
  margin-top: 14px !important;

  :global(.ant-list-items) {
    padding: 6px 0;
  }
  :global(.ant-list-item) {
    border-bottom: 0 !important;
    color: var(--theme-status-inactive);
    padding: 0;
  }
  :global(.ant-list-header) {
    border-color: var(--theme-stage-border-strong) !important;
    color: var(--theme-status-inactive);
    padding-bottom: 6px;
    padding-top: 0;
  }
  :global(.ant-list-item-meta-title) {
    color: var(--theme-status-inactive);
    font-size: 11px !important;
  }
}
.card:global(.ant-card) {
  border-radius: 8px;
  height: 256px;
  width: 330px;

  :global(.ant-card-meta) {
    align-items: center;
    height: 40px;
  }
  :global(.ant-card-meta-detail) {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
  }
  :global(.ant-card-meta-title) {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0 !important;
  }
  :global(.ant-card-meta-description) {
    display: flex;
    font-size: 12px;
    gap: 4px;
  }
  :global(.ant-card-meta-avatar) {
    display: flex;
    font-size: var(--icon-huge);

    .spinner {
      color: var(--theme-brand);
      font-size: 24px;
    }
  }
  :global(.ant-card-actions) span {
    float: right;
    right: 20px;
  }
  :global(.ant-card-body) {
    height: 256px;
    padding: 20px;
  }
}
.stateRunning {
  color: var(--theme-status-success);
}
.stateFailed {
  color: var(--theme-status-critical);
}
.huge {
  font-size: var(--icon-huge);
}
.cardContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.buttonsWrapper {
  bottom: 0;
  display: flex;
  gap: 5px;
  justify-content: flex-end;

  > * {
    width: 50%;
  }
}
