@font-face {
  font-display: block;
  font-family: 'Objektiv Mk3';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/objektiv-mk3-regular.woff2?07a67e') format('woff2'),
    url('../assets/fonts/objektiv-mk3-regular.woff?07a67e') format('woff');
}

@font-face {
  font-display: block;
  font-family: 'Objektiv Mk3';
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/objektiv-mk3-regular-italic.woff2?07a67e') format('woff2'),
    url('../assets/fonts/objektiv-mk3-regular-italic.woff?07a67e') format('woff');
}

@font-face {
  font-display: block;
  font-family: 'Objektiv Mk3';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/objektiv-mk3-bold.woff2?07a67e') format('woff2'),
    url('../assets/fonts/objektiv-mk3-bold.woff?07a67e') format('woff');
}

@font-face {
  font-display: block;
  font-family: 'Objektiv Mk3';
  font-style: italic;
  font-weight: bold;
  src: url('../assets/fonts/objektiv-mk3-bold-italic.woff2?07a67e') format('woff2'),
    url('../assets/fonts/objektiv-mk3-bold-italic.woff?07a67e') format('woff');
}

@font-face {
  font-display: block;
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/source-code-pro-bold.woff2?07a67e') format('woff2'),
    url('../assets/fonts/source-code-pro-bold.woff?07a67e') format('woff');
}

@font-face {
  font-display: block;
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/source-code-pro-regular.woff2?07a67e') format('woff2'),
    url('../assets/fonts/source-code-pro-regular.woff?07a67e') format('woff');
}
