$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.base {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 16px 16px;
  position: relative;
  width: var(--nav-side-bar-width-max);
}
.base > [class*='Avatar_base_'] {
  flex-shrink: 0;
}
.base > span {
  color: var(--theme-stage-on);
  font-size: 12px;
  margin-left: 16px;
  overflow: hidden;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
