$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.base {
  display: flex;
  flex-grow: 1;

  [class*='NavigationSideBar_base_'] {
    flex-grow: 0;
    position: relative;
    // Avoid setting anything else with a z-index of 100 or higher  in the non-nav/main content area
    z-index: 100;
  }
  [class*='NavigationTabbar_base'] {
    display: none;
    flex-grow: 0;
    // Avoid setting anything else with a z-index of 100 or higher  in the non-nav/main content area
    z-index: 100;
  }
  & > main {
    flex-grow: 1;
    height: calc(var(--vh, 1vh) * 100 - var(--nav-bottom-bar-height));
    overflow: auto;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;

    [class*='NavigationSideBar_base_'] {
      display: none;
    }
    [class*='NavigationTabbar_base'] {
      display: flex;
    }
    & > main {
      height: calc(var(--vh, 1vh) * 100 - (2 * var(--nav-bottom-bar-height)));
    }
  }
}
