$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.base {
  align-items: center;
  border-radius: 100%;
  display: flex;
  font-size: 10px;
  font-weight: bold;
  height: 24px;
  justify-content: center;
  line-height: 1;
  width: 24px;
}
.base.large {
  font-size: 24px;
  height: 64px;
  width: 64px;
}
.base.extra-large {
  font-size: 96px;
  height: 192px;
  width: 192px;
}
