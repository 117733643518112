$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.base {
  color: var(--theme-ix-on);
  display: flex;
  justify-content: flex-end;

  button {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    font: inherit;
    height: 100%;
    justify-content: center;
    outline: inherit;
    padding: 0;
    width: 100%;

    &:hover {
      color: var(--theme-status-active);
    }
  }
  button:disabled,
  button[disabled] {
    color: var(--theme-float-on);
    cursor: not-allowed;

    &:hover {
      color: var(--theme-ix-on-active);
    }
  }
}
