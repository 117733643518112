$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.base {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    text-align: center;

    & > * {
      margin-bottom: 16px;
    }
    & > *:last-child {
      margin-bottom: 0;
    }
  }
}
