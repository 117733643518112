$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.singleOrg {
  align-items: center;
  display: flex;
  flex-direction: row;

  & > * {
    margin-left: 10px;
  }
  & > *:first-child {
    margin-left: 0;
  }
  .text {
    flex-grow: 1;
    text-align: left;
  }
}
.oneOrg {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.checkmark {
  float: right;
}
.orgFace {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  :global(.anticon-down) {
    margin-left: 10px;
  }
}
.id {
  color: var(--theme-stage-on-weak);
}
.mini {
  padding: 16px;

  .text {
    .name {
      font-size: 14px;
      margin-left: 6px;
    }
    .id {
      display: none;
    }
  }
}
.truncate.name {
  max-width: calc(100vw - 200px); // dependent on available width in navigation header
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
