html {
  /* Breadcrumb */

  .ant-breadcrumb {
    align-items: center;
    display: flex;

    li:last-child {
      /* stylelint-disable no-descending-specificity */

      a,
      span {
        color: var(--theme-float-on);
      }
    }
  }

  /* Button */

  .ant-btn {
    border-radius: var(--theme-border-radius);
  }
  .ant-btn:not(.ant-btn-sm) {
    padding: 4px 8px;
  }
  .ant-btn > i + span {
    margin-left: 8px;
  }
  .ant-btn.ant-btn-background-ghost {
    border-color: var(--theme-ix-border);
    color: var(--theme-ix-on);
  }
  .ant-btn.ant-btn-background-ghost:hover {
    border-color: var(--theme-ix-border-active);
    color: var(--theme-ix-on-active);
  }
  .ant-btn[disabled]:not(.ant-btn-link):not(.ant-btn-primary),
  .ant-btn[disabled]:not(.ant-btn-link):not(.ant-btn-primary):hover {
    background-color: var(--theme-ix-inactive);
    border-color: var(--theme-ix-border-inactive);
    color: var(--theme-ix-on-inactive);
  }
  .ant-btn-icon-only {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  /* Checkbox */

  .ant-checkbox > .ant-checkbox-inner {
    border-color: var(--theme-ix-border);
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    border-color: var(--theme-ix-border-active);
  }
  .ant-checkbox-checked > .ant-checkbox-inner::after {
    border-left: 0;
    border-top: 0;
  }

  /* Dropdown */

  .ant-dropdown-menu {
    background-color: var(--theme-float);
    border: solid var(--theme-stroke-width) var(--theme-float-border);
    border-radius: var(--theme-border-radius);
    box-shadow: var(--theme-elevation-strong);
  }

  /* Form */

  .ant-form-item-explain {
    align-items: center;
    display: flex;
    font-size: 12px;
  }

  /* Input */

  .ant-input-affix-wrapper {
    border-radius: var(--theme-border-radius);
  }
  .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
    padding: 6.5px 7.5px;
  }
  .ant-input-affix-wrapper.ant-input-affix-wrapper-sm {
    padding: 0 3px;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: var(--theme-focus-shadow);
  }
  .ant-input-affix-wrapper > .ant-input-prefix,
  .ant-input-affix-wrapper > .ant-input-suffix {
    align-items: center;
    display: flex;
  }
  .ant-input-affix-wrapper > .ant-input-prefix {
    margin-right: 6px;
  }
  .ant-input-affix-wrapper > .ant-input-suffix {
    margin-left: 6px;
  }
  .ant-input-affix-wrapper.ant-input-affix-wrapper-lg > .ant-input-prefix {
    margin-right: 7.5px;
  }
  .ant-input-affix-wrapper.ant-input-affix-wrapper-lg > .ant-input-suffix {
    margin-left: 7.5px;
  }
  .ant-input-affix-wrapper.ant-input-affix-wrapper-sm > .ant-input-prefix {
    margin-right: 3px;
  }
  .ant-input-affix-wrapper.ant-input-affix-wrapper-sm > .ant-input-suffix {
    margin-left: 3px;
  }
  .ant-input-affix-wrapper.ant-input-affix-wrapper-sm > .ant-input-prefix > i,
  .ant-input-affix-wrapper.ant-input-affix-wrapper-sm > .ant-input-suffix > i {
    font-size: 16px;
  }
  .ant-input-number-status-error,
  .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
  .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
    background-color: inherit;
  }

  /* Modal */

  .ant-modal > .ant-modal-content {
    border-radius: var(--theme-border-radius-strong);
    box-shadow: var(--theme-elevation-strong);
  }
  .ant-modal > .ant-modal-content > .ant-modal-header,
  .ant-modal > .ant-modal-content > .ant-modal-body {
    padding: 16px;
  }

  /* Notification */

  .ant-notification-notice {
    background-color: var(--theme-float);
    border: solid var(--theme-stroke-width) var(--theme-float-border);
    border-radius: var(--theme-border-radius);
    box-shadow: var(--theme-elevation-strong);
  }

  /* Picker */

  .ant-picker {
    border-radius: var(--theme-border-radius);
  }

  /* Progress */

  .ant-progress {
    line-height: 0;
  }

  /* Select */

  .ant-select > .ant-select-arrow {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
  }
  .ant-select.ant-select-open > .ant-select-arrow {
    transform: rotate(180deg);
  }
  .ant-select.ant-select-open.ant-select-show-search {
    min-width: 100px;
  }
  .ant-select-item-option-content {
    width: 0;
  }
  .ant-select-dropdown {
    background-color: var(--theme-float);
    border: solid var(--theme-stroke-width) var(--theme-float-border);
    border-radius: var(--theme-border-radius);
    box-shadow: var(--theme-elevation);
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: var(--theme-border-radius);
  }
  .ant-select-focused.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: var(--theme-focus-shadow);
  }
  .ant-select-multiple {
    .ant-select-selection-placeholder {
      color: var(--theme-ix-on-weak);
      opacity: 1;
    }
    .ant-select-selector
      .ant-select-selection-search:first-child
      .ant-select-selection-search-input {
      margin-left: 0;
    }
  }

  /* Space */

  .ant-space.ant-space-horizontal.ant-space-align-center > .ant-space-item {
    align-items: center;
    display: inline-flex;
  }

  /* Spin */

  .ant-spin-container {
    height: 100%;
  }
  .ant-spin-nested-loading {
    height: 100%;
    width: 100%;
  }
  .ant-spin-nested-loading > div > .ant-spin-spinning {
    max-height: inherit;
  }
  .ant-spin-show-text {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .ant-spin .ant-spin-dot {
    display: flex;
  }
  .ant-spin .ant-spin-text {
    color: var(--theme-background-on);
    font-style: italic;
    margin-top: 16px;
  }

  /* Statistic */

  .ant-statistic-title {
    font-size: 12px;
  }
  .ant-statistic-content {
    font-size: 16px;
  }

  /* Table */

  .ant-table {
    background-color: var(--theme-stage-weak);
  }
  .ant-table .ant-table-content > table > colgroup > col.ant-table-selection-col {
    width: 46px;
  }
  .ant-table .ant-table-tbody > tr [class*='tagPlus'] {
    opacity: 0;
    user-select: none;
  }
  .ant-table .ant-table-tbody > tr:hover [class*='tagPlus'] {
    opacity: 1;
    user-select: auto;
  }
  .ant-table .ant-table-tbody > tr > td.ant-table-cell {
    background-color: var(--theme-stage-weak);
  }
  .ant-table .ant-table-tbody > tr > td.ant-table-cell.ant-table-column-sort {
    filter: invert(2%);
  }
  .ant-table .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: var(--theme-stage);
  }
  .ant-table .ant-table-tbody > tr.ant-table-row.highlighted > td {
    background-color: var(--theme-stage);
    border-color: var(--theme-status-active);
    border-style: hidden hidden solid;
  }
  .ant-table .ant-table-tbody > tr.ant-table-row.clickable {
    cursor: pointer;
  }
  .ant-table .ant-table-tbody > tr.ant-table-row.clickable:hover > td {
    background-color: var(--theme-stage);
    border-color: var(--theme-status-active);
    border-style: hidden hidden solid;
  }
  .ant-table .ant-table-tbody > tr > td.ant-table-selection-column,
  .ant-table .ant-table-tbody > tr > td.fullCell {
    min-width: 32px;
    padding: 0;
    position: relative;

    & > * {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .ant-table.ant-table-bordered {
    border: solid var(--theme-stroke-width) var(--theme-stage-border);
    border-radius: var(--theme-border-radius);
  }
  .ant-table.ant-table-bordered > .ant-table-container,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table {
    border: 0;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-thead
    > tr
    > th {
    border-color: var(--theme-stage-border);
    border-style: solid;
    border-width: 0 var(--theme-stroke-width) var(--theme-stroke-width) 0;
    white-space: nowrap;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-thead
    > tr
    > th:last-child,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-thead
    > tr
    > th:only-child {
    border-right-width: 0;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-thead
    > tr
    > th:hover
    .ant-table-filter-column
    > .ant-table-filter-trigger {
    background-color: var(--theme-stage-strong);
    color: var(--theme-status-active);
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr
    > td {
    border-style: solid;
    border-width: 0 0 var(--theme-stroke-width) 0;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr:last-child
    > td {
    border-bottom: 0;
  }
  .ant-table-filter-dropdown {
    background-color: var(--theme-float);
    border: solid var(--theme-stroke-width) var(--theme-float-border);
    border-radius: var(--theme-border-radius);
    box-shadow: var(--theme-elevation-strong);
  }

  /* Tabs */

  .ant-tabs {
    height: 100%;
  }
  .ant-tabs .ant-tabs-tab-active > .ant-tabs-tab-btn {
    text-shadow: none;
  }
  .ant-tabs > .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs > .ant-tabs-content-holder {
    height: 100%;
  }
  .ant-tabs > .ant-tabs-content-holder > .ant-tabs-content {
    height: 100%;
    overflow: auto;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-color: var(--theme-stage-border);
  }
  .ant-tabs.no-padding {
    & > .ant-tabs-nav {
      padding-bottom: 0;
      padding-left: calc(16px + var(--theme-density) * 1px);
      padding-right: calc(16px + var(--theme-density) * 1px);
      padding-top: calc(16px + var(--theme-density) * 1px);
    }
  }

  /* Tabs Cards Style */

  .ant-tabs-card > .ant-tabs-nav > .ant-tabs-nav-wrap .ant-tabs-tab {
    background-color: var(--theme-surface-strong);
    border-color: var(--theme-surface-border);
  }
  .ant-tabs-card > .ant-tabs-nav > .ant-tabs-nav-wrap .ant-tabs-tab:hover {
    background-color: var(--theme-surface);
    color: var(--theme-surface-on);
  }
  .ant-tabs-card > .ant-tabs-nav > .ant-tabs-nav-wrap .ant-tabs-tab-active {
    background-color: var(--theme-surface);
    border-bottom-color: var(--theme-surface);
  }
  .ant-tabs-card > .ant-tabs-content-holder {
    background-color: var(--theme-surface);
    border-color: var(--theme-surface-border);
    border-style: solid;
    border-width: 0 var(--theme-stroke-width) var(--theme-stroke-width) var(--theme-stroke-width);
  }
  .ant-tabs-card > .ant-tabs-content-holder > .ant-tabs-content {
    padding: 16px;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-color: var(--theme-surface-border);
  }

  /* Card */

  .ant-card {
    background-color: var(--theme-surface);
    border-color: var(--theme-surface-border);
    color: var(--theme-surface-on);
  }
  .ant-card-head {
    border-color: var(--theme-surface-border);
    color: var(--theme-surface-on);
  }
  .ant-card-hoverable:hover {
    box-shadow: var(--theme-elevation);
  }

  /* Tooltip */

  .ant-tooltip {
    pointer-events: none;
  }
  .ant-tooltip-inner,
  .ant-tooltip-arrow-content,
  .ant-tooltip-arrow-content::before {
    background: var(--theme-float);
    color: var(--theme-float-on);
    opacity: 1;
  }
}
