$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

$transition-props: 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);

.base {
  position: relative;

  .trigger {
    cursor: pointer;
  }
  .content {
    opacity: 0;
    position: absolute;
    transition: opacity $transition-props;
    visibility: hidden;
    z-index: 1;
  }
  .content > :global(.ant-menu),
  .content > * {
    background-color: var(--theme-float);
    border: solid var(--theme-stroke-width) var(--theme-float-border);
    border-radius: var(--theme-border-radius);
    box-shadow: var(--theme-elevation);
  }
  .content > :global(.ant-menu) > li {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }
}
.base.arrow > .content::after {
  background-color: var(--theme-float);
  border-color: var(--theme-float-border);
  border-style: solid;
  content: '';
  height: 8px;
  position: absolute;
  width: 8px;
}
.base.arrow.bottom > .content::after {
  border-width: var(--theme-stroke-width) 0 0 var(--theme-stroke-width);
  left: 50%;
  top: 0;
  transform: translate(-4px, -4px) rotate(45deg);
}
.base.arrow.bottomLeft > .content::after {
  border-width: var(--theme-stroke-width) 0 0 var(--theme-stroke-width);
  left: 0;
  top: 0;
  transform: translate(8px, -4px) rotate(45deg);
}
.base.arrow.bottomRight > .content::after {
  border-width: var(--theme-stroke-width) 0 0 var(--theme-stroke-width);
  right: 0;
  top: 0;
  transform: translate(-8px, -4px) rotate(45deg);
}
.base.arrow.left > .content::after {
  border-width: var(--theme-stroke-width) var(--theme-stroke-width) 0 0;
  right: 0;
  top: 50%;
  transform: translate(4px, -4px) rotate(45deg);
}
.base.arrow.leftTop > .content::after {
  border-width: var(--theme-stroke-width) var(--theme-stroke-width) 0 0;
  right: 0;
  top: 0;
  transform: translate(4px, 8px) rotate(45deg);
}
.base.arrow.leftBottom > .content::after {
  border-width: var(--theme-stroke-width) var(--theme-stroke-width) 0 0;
  bottom: 0;
  right: 0;
  transform: translate(4px, -8px) rotate(45deg);
}
.base.arrow.right > .content::after {
  border-width: 0 0 var(--theme-stroke-width) var(--theme-stroke-width);
  left: 0;
  top: 50%;
  transform: translate(-4px, -4px) rotate(45deg);
}
.base.arrow.rightTop > .content::after {
  border-width: 0 0 var(--theme-stroke-width) var(--theme-stroke-width);
  left: 0;
  top: 0;
  transform: translate(-4px, 8px) rotate(45deg);
}
.base.arrow.rightBottom > .content::after {
  border-width: 0 0 var(--theme-stroke-width) var(--theme-stroke-width);
  bottom: 0;
  left: 0;
  transform: translate(-4px, -8px) rotate(45deg);
}
.base.arrow.top > .content::after {
  border-width: 0 var(--theme-stroke-width) var(--theme-stroke-width) 0;
  bottom: 0;
  left: 50%;
  transform: translate(-4px, 4px) rotate(45deg);
}
.base.arrow.topLeft > .content::after {
  border-width: 0 var(--theme-stroke-width) var(--theme-stroke-width) 0;
  bottom: 0;
  left: 0;
  transform: translate(8px, 4px) rotate(45deg);
}
.base.arrow.topRight > .content::after {
  border-width: 0 var(--theme-stroke-width) var(--theme-stroke-width) 0;
  bottom: 0;
  right: 0;
  transform: translate(-8px, 4px) rotate(45deg);
}
.base.visible {
  .content {
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }
}
.base.displayToggle .content {
  display: none;
  opacity: 1;
}
.base.displayToggle.visible .content {
  display: block;
}
