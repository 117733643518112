$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.base {
  background-color: var(--theme-overlay);
  display: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  .sheet {
    background-color: var(--theme-float);
    bottom: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
  }
  .item {
    align-items: center;
    color: var(--theme-float-on);
    display: flex;
    height: var(--nav-bottom-bar-height);

    [class^='Icon_base_'] {
      padding: 0 16px;
    }
    .label {
      font-size: 14px;
    }
    .spacer {
      width: calc(24px + 2 * 16px);
    }
  }
}
.sheetEnter {
  display: flex;
  opacity: 0;

  .sheet {
    bottom: -50%;
  }
}
.sheetEnterActive {
  opacity: 1;
  transition: opacity var(--animation-curve);

  .sheet {
    bottom: 0;
    transition: bottom var(--animation-curve);
  }
}
.sheetEnterDone {
  display: flex;

  .sheet {
    bottom: 0;
  }
}
.sheetExit {
  display: flex;
  opacity: 1;

  .sheet {
    bottom: 0;
  }
}
.sheetExitActive {
  opacity: 0;
  transition: opacity var(--animation-curve);

  .sheet {
    bottom: -50%;
    transition: bottom var(--animation-curve);
  }
}
.sheetExitDone {
  display: none;

  .sheet {
    bottom: -50%;
  }
}
