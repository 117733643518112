$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

@import-normalize;

@import 'icons';
@import 'fonts';
@import 'antd';

html,
body,
#root {
  color-scheme: var(--theme-color-scheme);
  font-family: var(--font-family);
  font-size: 16px;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
}
h1 {
  font-size: 32px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}
#clipboard {
  opacity: 0;
  user-select: none;
}
code {
  background-color: var(--theme-colors-monochrome-13);
  border-radius: var(--theme-border-radius);
  font-family: var(--font-family-code);
  padding: 0 4px;
}
code.block {
  display: block;
  margin: 8px 0;
  overflow-x: scroll;
  padding: 8px;
  white-space: pre;
}
