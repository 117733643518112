$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.cards {
  display: flex;
  flex-direction: row;
  gap: 8px;

  .card {
    background-color: var(--theme-background);
    border: 2px solid var(--theme-stage-border);
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    padding: 12px;
    width: 50%;

    .value {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .selected {
    background-color: var(--theme-background-weak);
    border: 2px solid var(--theme-status-active);
    box-sizing: border-box;
  }
}
.modalContent {
  margin: 24px 0;
  max-height: 526px;
  overflow-y: scroll;
  padding: 0 24px;

  :global(.ant-form-item-with-help) {
    margin-bottom: 24px;
  }
}
.sectionHeader {
  display: flex;
  justify-content: space-between;
}
.pools {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .card {
    border-radius: 3px;

    :global(.ant-form-item) {
      margin-bottom: 8px;
    }
    :global(.ant-card-body),
    :global(.ant-card-head) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
.formItemWithLink {
  label {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
