$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.base {
  user-select: none;
}
.tiny {
  font-size: var(--icon-tiny);
}
.small {
  font-size: var(--icon-small);
}
.medium {
  font-size: var(--icon-medium);
}
.large {
  font-size: var(--icon-large);
}
.big {
  font-size: var(--icon-big);
}
.great {
  font-size: var(--icon-great);
}
.huge {
  font-size: var(--icon-huge);
}
.enormous {
  font-size: var(--icon-enormous);
}
.giant {
  font-size: var(--icon-giant);
}
.jumbo {
  font-size: var(--icon-jumbo);
}
.mega {
  font-size: var(--icon-mega);
}
