$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.base {
  display: grid;
}
.border {
  grid-gap: 1px;

  & > * {
    outline: 1px solid var(--theme-colors-monochrome-12);
    position: relative;
    z-index: 0;
  }
}
