$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

/* stylelint-disable no-descending-specificity */

.base {
  background-color: var(--theme-stage);
  border-right: solid var(--theme-stroke-width) var(--theme-stage-border);
  box-sizing: border-box;
  display: flex;
  flex-basis: var(--nav-side-bar-width-max);
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: visible;

  header {
    display: flex;
    flex-direction: column;
  }
  main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  section.launch {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 16px 0;
    width: var(--nav-side-bar-width-max);
  }
  section.top {
    flex-grow: 1;
  }
  footer {
    position: relative;
  }
  .version {
    background-color: var(--theme-stage-strong);
    color: var(--theme-stage-on-strong);
    font-size: 11px;
    font-style: italic;
    height: 32px;
    width: 100%;
  }
  .versionLabel {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
  }
  .launchBlock {
    align-items: center;
    display: flex;
    height: 32px;
    width: 206px;

    .launchButton {
      background-color: transparent;
      border-color: var(--theme-stage-border);
      border-radius: var(--theme-border-radius);
      border-style: solid;
      border-width: var(--theme-stroke-width);
      color: var(--theme-stage-on);
      flex-grow: 1;
      flex-shrink: 1;
      overflow: hidden;
    }
    .launchIcon {
      align-items: center;
      background-color: transparent;
      border-color: var(--theme-stage-border);
      border-radius: 0 var(--theme-border-radius) var(--theme-border-radius) 0;
      border-style: solid;
      border-width: var(--theme-stroke-width);
      color: var(--theme-stage-on);
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      width: 32px;
    }
  }
  .launchBlock:hover {
    .launchButton,
    .launchIcon {
      border-color: var(--theme-stage-border-strong);
    }
  }
  header .navItem {
    display: flex;
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .navItem {
    color: var(--theme-stage-on);
    display: block;
    height: 48px;
    position: relative;
    width: var(--nav-side-bar-width-max);

    [class*='Icon_base_'] {
      left: 16px;
      position: absolute;
      top: 12px;
    }
    .label {
      font-size: 12px;
      height: 24px;
      left: 56px;
      line-height: 24px;
      overflow: hidden;
      position: absolute;
      top: 12px;
      white-space: nowrap;
      width: auto;
    }
    .status {
      background-color: var(--theme-status-active);
      border-radius: 8px;
      bottom: 16px;
      color: var(--theme-status-active-on);
      font-size: 11px;
      height: 0 16px;
      padding: 0 6px;
      position: absolute;
      right: 16px;
      white-space: nowrap;
    }
  }
  .navItem.active,
  .navItem.active:hover {
    background-color: var(--theme-stage-strong);
    color: var(--theme-stage-on-strong);
    cursor: default;
  }
  .navItem:hover {
    color: var(--theme-stage-on-strong);
  }
  .user {
    background-color: var(--theme-stage-weak);
  }
  .user:hover > span {
    color: var(--theme-stage-on-weak);
  }
}
.collapsedAppear {
  flex-basis: 0;
  overflow: hidden;
}
.collapsedEnter,
.collapsedExitDone {
  flex-basis: var(--nav-side-bar-width-max);

  section.launch {
    width: var(--nav-side-bar-width-max);
  }
  .navItem {
    width: var(--nav-side-bar-width-max);

    .label {
      opacity: 1;
    }
    .status {
      right: 16px;
      transform: translateX(0);
    }
  }
  .navItem.hasStatus {
    height: 48px;

    .status {
      bottom: 16px;
    }
  }
  .user {
    width: var(--nav-side-bar-width-max);

    span {
      opacity: 1;
    }
  }
}
.collapsedEnterActive {
  flex-basis: var(--nav-side-bar-width-min);
  transition: flex-basis var(--animation-curve);

  section.launch {
    transition: width var(--animation-curve);
    width: var(--nav-side-bar-width-min);
  }
  .logo {
    height: 24px;
    left: 12px;
    top: 16px;
    transition: height var(--animation-curve), left var(--animation-curve),
      top var(--animation-curve), width var(--animation-curve);
    width: 32px;
  }
  .logoIcon {
    height: 24px;
    left: 0;
    transition: height var(--animation-curve), left var(--animation-curve),
      width var(--animation-curve);
    width: 32px;
  }
  .logoLabel {
    opacity: 0;
    transition: opacity var(--animation-curve), width var(--animation-curve);
    width: 0;
  }
  .launchBlock {
    transition: width var(--animation-curve);
    width: 32px;

    .launchButton {
      opacity: 0;
      padding: 0;
      transition: opacity var(--animation-curve), padding var(--animation-curve),
        width var(--animation-curve);
      width: 0;
    }
    .launchIcon {
      border-radius: 100%;
      transition: border-radius var(--animation-curve);
    }
  }
  .navItem {
    transition: height var(--animation-curve), width var(--animation-curve);
    width: var(--nav-side-bar-width-min);

    .label {
      opacity: 0;
      transition: opacity var(--animation-curve);
    }
    .status {
      right: 50%;
      transform: translateX(50%);
      transition: bottom var(--animation-curve), right var(--animation-curve),
        transform var(--animation-curve);
    }
  }
  .navItem.hasStatus {
    height: 72px;

    .status {
      bottom: 12px;
    }
  }
  .user {
    transition: width var(--animation-curve);
    width: var(--nav-side-bar-width-min);

    span {
      opacity: 0;
      transition: opacity var(--animation-curve);
    }
  }
}
.collapsedExit,
.collapsedEnterDone {
  flex-basis: var(--nav-side-bar-width-min);

  section.launch {
    width: var(--nav-side-bar-width-min);
  }
  .logo {
    height: 24px;
    left: 12px;
    top: 16px;
    width: 32px;
  }
  .logoIcon {
    height: 24px;
    left: 0;
    width: 32px;
  }
  .logoLabel {
    opacity: 0;
    width: 0;
  }
  .launchBlock {
    width: 32px;

    .launchButton {
      opacity: 0;
      padding: 0;
      width: 0;
    }
    .launchIcon {
      border-radius: 100%;
    }
  }
  .navItem {
    width: var(--nav-side-bar-width-min);

    .label {
      opacity: 0;
    }
    .status {
      right: 50%;
      transform: translateX(50%);
    }
  }
  .navItem.hasStatus {
    height: 72px;

    .status {
      bottom: 12px;
    }
  }
  .user {
    width: var(--nav-side-bar-width-min);

    span {
      opacity: 0;
    }
  }
}
.collapsedExit {
  .navItem {
    .label {
      display: block;
    }
  }
}
.collapsedEnterDone {
  .navItem {
    .label {
      display: none;
    }
  }
}
.collapsedExitActive {
  flex-basis: var(--nav-side-bar-width-max);
  transition: flex-basis var(--animation-curve);

  section.launch {
    transition: width var(--animation-curve);
    width: var(--nav-side-bar-width-max);
  }
  .logo {
    height: 64.94px;
    left: 60px;
    top: 24px;
    transition: height var(--animation-curve), left var(--animation-curve),
      top var(--animation-curve), width var(--animation-curve);
    width: 128px;
  }
  .logoIcon {
    height: 40px;
    left: 32px;
    transition: height var(--animation-curve), left var(--animation-curve),
      width var(--animation-curve);
    width: 52px;
  }
  .logoLabel {
    opacity: 1;
    transition: opacity var(--animation-curve), width var(--animation-curve);
    width: 120px;
  }
  .launchBlock {
    transition: width var(--animation-curve);
    width: 206px;

    .launchButton {
      opacity: 1;
      padding: 0 16px;
      transition: opacity var(--animation-curve), padding var(--animation-curve),
        width var(--animation-curve);
      width: 100%;
    }
    .launchIcon {
      border-radius: 0 var(--theme-border-radius) var(--theme-border-radius) 0;
      transition: border-radius var(--animation-curve);
    }
  }
  .navItem {
    transition: height var(--animation-curve), width var(--animation-curve);
    width: var(--nav-side-bar-width-max);

    .label {
      opacity: 1;
      transition: opacity var(--animation-curve);
    }
    .status {
      right: 16px;
      transform: translateX(0);
      transition: bottom var(--animation-curve), right var(--animation-curve),
        transform var(--animation-curve);
    }
  }
  .navItem.hasStatus {
    height: 48px;

    .status {
      bottom: 16px;
    }
  }
  .user {
    transition: width var(--animation-curve);
    width: var(--nav-side-bar-width-max);

    span {
      opacity: 1;
      transition: opacity var(--animation-curve);
    }
  }
}
