$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

$transtion-type: ease-in-out 0.6s;

.base {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .container {
    cursor: pointer;
  }
}
.mode {
  color: var(--theme-colors-monochrome-9);
  display: inline-block;
  padding-left: 10px;
  vertical-align: middle;
}
.toggler {
  border: none;
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  transition: border-radius $transtion-type, border-style $transtion-type,
    border-width $transtion-type, width $transtion-type, height $transtion-type,
    background-color $transtion-type, border-top $transtion-type, border-bottom $transtion-type,
    border-right $transtion-type, border-left $transtion-type, box-shadow $transtion-type;
  vertical-align: middle;
  width: 20px;
}
.system {
  background-color: gold;
  border-bottom-color: gold;
  border-left-color: black;
  border-radius: 10px;
  border-right-color: gold;
  border-style: solid;
  border-top-color: black;
  border-width: 10px;
}
.light {
  background-color: gold;
  border-bottom-color: gold;
  border-left-color: gold;
  border-radius: 10px;
  border-right-color: gold;
  border-style: solid;
  border-top-color: gold;
  border-width: 10px;
}
.dark {
  background-color: black;
  box-shadow: inset -4.5px -4px 1px 1px gold;
}
