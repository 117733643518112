$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.dropdownTable {
  table {
    font-size: 11px;
  }
}
.dropdownTableRow {
  cursor: pointer;
}
.tableWrapper {
  margin-top: 20px;
  overflow-x: scroll;
  width: 390px;
}
.dropdownButton {
  align-items: center;
  display: flex !important;
  justify-content: space-between;
  width: 100%;

  span.label {
    float: left;
  }
  span.icon {
    float: right;
  }
}
