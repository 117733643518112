$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.base {
  background-color: var(--theme-background);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
