$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.noFooter :global(.ant-modal-confirm-btns) {
  display: none;
}
.code {
  font-size: 12px;
}
.copyContainer {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}
