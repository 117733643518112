$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.determined {
  &.onDarkHorizontal,
  &.onLightHorizontal {
    height: 30px;
    width: 192px;
  }
  &.onDarkVertical,
  &.onLightVertical {
    height: 64.95px;
    width: 120px;
  }
}
.hpe {
  &.onDarkHorizontal,
  &.onLightHorizontal,
  &.onDarkVertical,
  &.onLightVertical {
    height: 33px;
    width: 190px;
  }
}
