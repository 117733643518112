$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.base {
  background-color: var(--theme-stage);
  height: var(--nav-bottom-bar-height);

  .toolbar {
    align-items: center;
    display: flex;
    width: 100%;
  }
  .toolbar > * {
    flex-grow: 1;
  }
  .toolbarItem {
    align-items: center;
    color: var(--theme-stage-on);
    display: flex;
    height: var(--nav-bottom-bar-height);
    justify-content: center;
    position: relative;

    .status {
      background-color: var(--theme-status-active);
      border-radius: 8px;
      bottom: 8px;
      color: var(--theme-status-active-on);
      font-size: 11px;
      height: 0 16px;
      padding: 0 6px;
      position: absolute;
      right: 16px;
      white-space: nowrap;
    }
  }
  .toolbarItem.active {
    background-color: var(--theme-stage-strong);
    color: var(--theme-stage-on-strong);
  }
  .user {
    width: 100%;
  }
}
