$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.formItemWithLink {
  label {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.modalContent {
  margin: 24px 0;
  max-height: 526px;
  overflow-y: scroll;
  padding: 0 24px;
}
.formWrapper,
.form > * {
  margin: 24px 0;
}
