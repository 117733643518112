$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 1024px;
$breakpoint-desktop-medium: 1200px;
$breakpoint-desktop-large: 1400px;

.running-state {
  color: var(--theme-colors-states-success);
}
.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  width: 150px;
}
